import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import BarChartRoundedIcon from "@material-ui/icons/BarChartRounded";
import CachedIcon from "@material-ui/icons/Cached";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import ColorLensOutlinedIcon from "@material-ui/icons/ColorLensOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import EventAvailableOutlinedIcon from "@material-ui/icons/EventAvailableOutlined";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import RepeatRoundedIcon from "@material-ui/icons/RepeatRounded";
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";
import AdjustIcon from "../../img/adjust-icon.svg";
import CalendarSyncImg from "../../img/calendar-sync.svg";
import CoffeeIcon from "../../img/coffee.svg";
import CogIcon from "../../img/cog-icon.svg";
import ConnectedCalendarsImg from "../../img/connected-calendars.svg";
import GcalLogo from "../../img/gcal-outline.svg";
import GridIcon from "../../img/grid.svg";
import HabitsImg from "../../img/habits.svg";
import HashtagIcon from "../../img/hash.svg";
import Hourglass from "../../img/hourglass.svg";
import HoursImg from "../../img/hours.svg";
import PuzzleIcon from "../../img/puzzle-icon.svg";
import SlackLogo from "../../img/slack-outline.svg";
import UserAddIcon from "../../img/user-add-icon.svg";
import UserGroup from "../../img/user-group-icon.svg";
import UsersIcon from "../../img/users-icon.svg";
import WavingHand from "../../img/waving-hand.svg";
import { QuestTypeStr } from "../../reclaim-api/Users";
import { YouTubeOptions } from "../../types/youtube";
import { getSchedulingLinkEditUrl, getSchedulingLinksUrl } from "../../utils/router";
import { QuestGenericPopperContent } from "./QuestGenericPopperContent";
import { QuestGroup, QuestGroupMap, QuestsConfig } from "./quests.types";

const SLACK_APP_ID = process.env.NEXT_PUBLIC_SLACK_APP_ID;

export const DEFAULT_VIDEO_PLAYER_OPTIONS: YouTubeOptions = {
  autoplay: 1,
};

export const QUESTS_DIRECTORY: { [QG in QuestGroup]: (QuestTypeStr & QuestGroupMap[QG])[] } = {
  GETTING_STARTED: [
    "WELCOME_TO_RECLAIM",
    "SYNC_CALENDAR",
    "SET_AVAILABILITY",
    "SET_SCHEDULING_HOURS",
    "INSTALL_GCAL_ADDON",
    "INSTALL_SLACK_INTEGRATION",
  ],
  HABITS: ["HABITS_OVERVIEW", "CREATE_AND_CUSTOMIZE_HABITS", "MANAGE_HABITS"],
  SMART_MEETINGS: [
    "SCHEDULING_LINKS_OVERVIEW",
    "SETUP_SCHEDULING_LINKS",
    "SETUP_TEAM_LINKS",
    "SMART_ONE_ON_ONE_OVERVIEW",
    "SETUP_FIRST_SMART_ONE_ON_ONE",
    "MANAGE_SMART_ONE_ON_ONE",
  ],
  TASKS: ["TASK_OVERVIEW", "ADD_FIRST_TASK", "ADD_TASK_INTEGRATION", "MANAGE_TASKS", "SET_TASK_DEFAULTS"],
  CUSTOMIZE_CALENDAR: [
    "AUTO_SCHEDULE_BREAK_AND_TRAVEL_TIME",
    "SETUP_NO_MEETING_DAYS",
    "CUSTOMIZE_EVENT_COLOR_CODING",
    "TRACK_AND_ANALYZE_PRODUCTIVITY",
  ],
  INVITE_TEAM: ["ADD_MEMBERS_TO_TEAM", "SELECT_PLAN"],
};

export const QUESTS_CONFIG: QuestsConfig = {
  /********************/
  /*  GETTING STARTED */
  /********************/
  GETTING_STARTED: {
    id: "GETTING_STARTED",
    title: "Get started",
    quests: [
      {
        id: "WELCOME_TO_RECLAIM",
        groupId: "GETTING_STARTED",
        title: "Welcome to Reclaim",
        description: "Watch a 2-minute video to learn how Reclaim works.",
        shortDescription: "A quick video on core concepts",
        duration: "2min",
        icon: WavingHand,
        steps: [{ id: "WATCH_WELCOME_VIDEO", type: "video", videoLink: "hJyct6lLAM0" }],
      },
      {
        id: "SET_AVAILABILITY",
        groupId: "GETTING_STARTED",
        title: "Set your availability",
        description: "Tell Reclaim which calendars to use to check your availability.",
        shortDescription: "Set availability from other calendars",
        duration: "4min",
        entryPathname: "/settings/accounts",
        indeterminant: true,
        icon: CalendarTodayOutlinedIcon,
        steps: [
          {
            id: "ADD_AVAILABILITY_CALENDARS",
            type: "action",
            popoverConfig: {
              description: 'Tap "Edit" to select which calendars you want Reclaim to check for your availability.',
              svg: ConnectedCalendarsImg,
            },
          },
          {
            id: "SELECT_A_CALENDAR",
            type: "orb",
            popoverConfig: {
              description: "Select which calendar to connect below.",
            },
          },
          {
            id: "SAVE_CONNECTED_CALENDAR",
            type: "action",
            popoverConfig: {
              description: "Save to connect the calendar.",
            },
          },
        ],
      },
      {
        id: "SYNC_CALENDAR",
        groupId: "GETTING_STARTED",
        title: "Sync your calendars",
        description: "Automatically sync & copy events across calendars to prevent overbookings.",
        shortDescription: "Sync events across calendars",
        duration: "4min",
        entryPathname: "/sync",
        indeterminant: true,
        icon: CachedIcon,
        steps: [
          {
            id: "CLICK_CREATE_NEW_SYNC",
            type: "action",
            popoverConfig: {
              description: 'Tap "New Sync" to create a Calendar Sync policy.',
              svg: CalendarSyncImg,
            },
          },
          {
            id: "CLICK_NEXT_PREFERENCES",
            type: "action",
            popoverConfig: {
              description:
                "Set your preferences for how events get synced & copied to the destination calendar, including privacy options & colors.",
            },
          },
          {
            id: "SYNC_PRIVACY",
            type: "orb",
            popoverConfig: {
              description: "Set the events that Reclaim should sync, how they should appear, & more.",
            },
          },
          {
            id: "CLICK_SYNC_CREATION",
            type: "action",
            popoverConfig: {
              description: "Time to sync your events.",
            },
          },
        ],
      },
      {
        id: "SET_SCHEDULING_HOURS",
        groupId: "GETTING_STARTED",
        title: "Set your scheduling hours",
        description: "Set your preferred personal, working & meeting hours.",
        shortDescription: "Schedule around your hours",
        duration: "2min",
        entryPathname: "/settings/hours",
        indeterminant: true,
        icon: Hourglass,
        steps: [
          {
            id: "MEETING_HOURS",
            component: QuestGenericPopperContent,
            type: "orb",
            popoverConfig: {
              description: "Update the hours you're available for scheduling Reclaim smart events.",
              svg: HoursImg,
            },
          },
          {
            id: "HINT_HOURS_INPUT",
            component: QuestGenericPopperContent,
            type: "orb",
            popoverConfig: {
              description: "Toggle the days of the week & update the range of hours for each day.",
            },
          },
          {
            id: "HOURS_INPUT",
            type: "action",
            popoverConfig: {
              description: "Begin editing your hours.",
            },
          },
        ],
      },
      {
        id: "INSTALL_GCAL_ADDON",
        groupId: "GETTING_STARTED",
        title: "Install the Google Calendar Add-on",
        description: "Manage Reclaim from Google Calendar & share Scheduling Links from Gmail.",
        shortDescription: "Share scheduling links from Gmail",
        duration: "1min",
        icon: GcalLogo,
        indeterminant: true,
        steps: [
          {
            id: "GOTO_GOOGLE_ADDON",
            type: "url",
            url: "https://workspace.google.com/marketplace/app/reclaimai/950518663892",
          },
        ],
      },
      {
        id: "INSTALL_SLACK_INTEGRATION",
        groupId: "GETTING_STARTED",
        title: "Install the Slack integration",
        description: "Auto-sync your Slack status to your calendar & manage Reclaim events in Slack.",
        shortDescription: "Auto-sync your Slack status",
        duration: "1min",
        icon: SlackLogo,
        indeterminant: true,
        steps: [
          {
            id: "GOTO_SLACK_INTEGRATION_LISTING",
            type: "url",
            url: `https://slack.com/app_redirect?app=${SLACK_APP_ID}`,
          },
        ],
      },
    ],
  },
  /********************/
  /*      HABITS      */
  /********************/
  HABITS: {
    id: "HABITS",
    title: "Set your Habits",
    quests: [
      {
        id: "HABITS_OVERVIEW",
        groupId: "HABITS",
        title: "Habits overview",
        description: "Watch a 1-minute video on how to auto-schedule time for your recurring routines.",
        shortDescription: "A quick video on core habit concepts",
        duration: "1min",
        icon: RepeatRoundedIcon,
        steps: [{ id: "WATCH_HABITS_OVERVIEW_VIDEO", type: "video", videoLink: "KEUG_NHtEsI" }],
      },
      {
        id: "CREATE_AND_CUSTOMIZE_HABITS",
        groupId: "HABITS",
        title: "Create & customize your Habits",
        description: "Set up & prioritize your Habits to keep your workweek focused & balanced.",
        shortDescription: "Add & customize your routines",
        duration: "4min",
        entryPathname: "/habits",
        indeterminant: true,
        icon: AdjustIcon,
        steps: [
          {
            id: "CREATE_NEW_HABIT",
            type: "action",
            popoverConfig: {
              description: "Tap to create a new Habit.",
              svg: HabitsImg,
            },
          },
          {
            id: "HABIT_FORM_INFORMATION",
            type: "orb",
            popoverConfig: {
              description:
                "Set preferences for how you want the Habit to schedule, & how it will appear on your calendar.",
            },
          },
          {
            id: "SAVE_HABIT",
            type: "action",
            popoverConfig: {
              description: 'Tap "Save" to start automatically scheduling your Habit.',
            },
          },
        ],
      },
      {
        id: "MANAGE_HABITS",
        groupId: "HABITS",
        title: "Manage Habits on your calendar",
        description: "Learn how to adjust Habit events in your schedule.",
        shortDescription: "How to work with Habits on your schedule",
        duration: "3min",
        entryPathname: "/planner",
        icon: TodayOutlinedIcon,
        steps: [
          {
            id: "PLANNER_HABIT_MANAGEMENT",
            type: "orb",
            popoverConfig: {
              description: "Watch a quick overview on how to manage your Habit events.",
              img: "/img/quests/habits-planner3.gif",
            },
          },
          { id: "VIDEO_HABIT_MANAGEMENT", type: "video", videoLink: "4PxE7M_eNTg" },
        ],
      },
    ],
  },
  /********************/
  /*  SMART MEETINGS  */
  /********************/
  SMART_MEETINGS: {
    id: "SMART_MEETINGS",
    title: "Set up your smart meetings",
    quests: [
      {
        id: "SCHEDULING_LINKS_OVERVIEW",
        groupId: "SMART_MEETINGS",
        title: "Scheduling Links overview",
        description:
          "Learn how to share your availability & get the right meetings scheduled sooner with Scheduling Links.",
        shortDescription: "How to use Reclaim's scheduling links",
        duration: "2min",
        icon: LinkRoundedIcon,
        steps: [{ id: "WATCH_SCHEDULING_LINKS_VIDEO", type: "video", videoLink: "xty1wE0ZLyU" }],
      },
      {
        id: "SETUP_SCHEDULING_LINKS",
        groupId: "SMART_MEETINGS",
        title: "Set up your Scheduling Links ",
        description: "Create your Scheduling Links & share your Booking Pages.",
        shortDescription: "Allow others to book time with you",
        duration: "5min",
        entryPathname: getSchedulingLinksUrl(),
        icon: DateRangeOutlinedIcon,
        indeterminant: true,
        steps: [
          {
            id: "CLICK_SL_ONBOARDING",
            type: "action",
            popoverConfig: {
              description: 'Tap "Set it up" to begin the Scheduling Links onboarding.',
            },
          },
          {
            id: "CLICK_DONE_SL_ONBOARDING",
            type: "action",
            popoverConfig: {
              description: 'Tap "Done" to complete Scheduling Links onboarding.',
            },
          },
        ],
      },
      {
        id: "SETUP_TEAM_LINKS",
        groupId: "SMART_MEETINGS",
        title: "Set up your team links",
        description: "Create a team Scheduling Link with multiple team members to share your combined availability.",
        shortDescription: "Send links based on your team's availability",
        duration: "2min",
        entryPathname: getSchedulingLinkEditUrl("new"),
        icon: UserGroup,
        indeterminant: true,
        steps: [
          {
            id: "SL_FORM_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "Create your team Scheduling Link for multiple team members. You can assign your team link to a group, set multiple durations, add your team members, & more.",
            },
          },
          {
            id: "CLICK_ADD_TEAM_MEMBERS",
            type: "orb",
            popoverConfig: {
              description: "Add team members to be meeting organizers for this link.",
            },
          },
          {
            id: "CLICK_CREATE_LINK",
            type: "action",
            popoverConfig: {
              description: "Create your team link.",
            },
          },
        ],
      },
      {
        id: "SMART_ONE_ON_ONE_OVERVIEW",
        groupId: "SMART_MEETINGS",
        title: "Smart 1:1s overview",
        description: "Learn how Smart 1:1s automatically schedule & manage your recurring one-on-one meetings.",
        shortDescription: "Auto-schedule & reschedule your 1:1s",
        duration: "4min",
        icon: UsersIcon,
        steps: [{ id: "WATCH_SMART_11s_VIDEO", type: "video", videoLink: "b3gluXGDOPQ" }],
      },
      {
        id: "SETUP_FIRST_SMART_ONE_ON_ONE",
        groupId: "SMART_MEETINGS",
        title: "Set up your first Smart 1:1",
        description: "Start converting your existing one-on-one meetings to Smart 1:1s.",
        shortDescription: "Convert existing 1:1 meetings",
        duration: "4min",
        entryPathname: "/one-on-ones",
        icon: EventAvailableOutlinedIcon,
        indeterminant: true,
        steps: [
          {
            id: "SMART_11_PAGE_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "You can create a new Smart 1:1, or convert your existing 1:1s that we detect into Reclaim Smart 1:1s.",
            },
          },
          {
            id: "CLICK_NEW_SMART_11",
            type: "action",
            popoverConfig: {
              description: "Create your Smart 1:1.",
            },
          },
          {
            id: "SMART_11_FORM_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "Enter the email of your invitee, set the duration & frequency of the recurring meeting, & add a location or videoconferencing link.",
            },
          },
          {
            id: "CREATE_SMART_11",
            type: "action",
            popoverConfig: {
              description: "Create your Smart 1:1.",
            },
          },
        ],
      },
      {
        id: "MANAGE_SMART_ONE_ON_ONE",
        groupId: "SMART_MEETINGS",
        title: "Manage Smart 1:1s on your calendar",
        description: "Learn how to manage your Smart 1:1s on your calendar during the week.",
        shortDescription: "How to manage your 1:1 meetings",
        duration: "2min",
        entryPathname: "/planner",
        icon: TodayOutlinedIcon,
        steps: [
          {
            id: "PLANNER_MANAGE_SMART_11",
            type: "orb",
            popoverConfig: {
              description: "Watch a quick video on managing your Smart 1:1s.",
              img: "/img/quests/quests-one-on-ones.gif",
            },
          },
          { id: "VIDEO_MANAGE_SMART_11", type: "video", videoLink: "PRXsrSRuKRE" },
        ],
      },
    ],
  },
  /********************/
  /*      TASKS       */
  /********************/
  TASKS: {
    id: "TASKS",
    title: "Make time for your tasks",
    quests: [
      {
        id: "TASK_OVERVIEW",
        groupId: "TASKS",
        title: "Tasks overview",
        description: "Learn how to automatically block flexible time for your Tasks.",
        shortDescription: "Block flexible time for your to-dos",
        duration: "2min",
        icon: AssignmentTurnedInOutlinedIcon,
        steps: [{ id: "WATCH_TASKS_VIDEO", type: "video", videoLink: "3rZjNBbRm7g" }],
      },
      {
        id: "ADD_FIRST_TASK",
        groupId: "TASKS",
        title: "Create your first Task",
        description: "Create a Task to start defending time on your calendar to get stuff done.",
        shortDescription: "Start blocking time to get stuff done",
        duration: "2min",
        entryPathname: "/tasks",
        icon: TodayOutlinedIcon,
        indeterminant: true,
        steps: [
          {
            id: "CLICK_NEW_TASK",
            type: "action",
            popoverConfig: {
              description: "Create a new Task here from anywhere in Reclaim.",
              img: "/img/quests/tasks.png",
            },
          },
          {
            id: "TASK_FORM_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "Give your Task a name, an estimated amount of time, & how you'd like to schedule (you can always add more time later).",
            },
          },
          {
            id: "SAVE_TASK",
            type: "action",
            popoverConfig: {
              description: "Tap save to create & auto-schedule time for this Task in your calendar.",
            },
          },
        ],
      },
      {
        id: "ADD_TASK_INTEGRATION",
        groupId: "TASKS",
        title: "Add Task integrations",
        description: "Integrate your project management app to automatically sync your Tasks.",
        shortDescription: "Auto-sync your tasks from other apps",
        duration: "4min",
        entryPathname: "/settings/integrations",
        icon: PuzzleIcon,
        indeterminant: true,
        steps: [
          {
            id: "TASKS_INTEGRATIONS_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "Integrate your project management app to automatically sync your Tasks to Reclaim & get them on the calendar (you may need to upgrade to a Pro or Team plan to integrate with some of these tools).",
            },
          },
        ],
      },
      {
        id: "MANAGE_TASKS",
        groupId: "TASKS",
        title: "Manage Tasks on your calendar",
        description: "Learn how to manage your Task events on your calendar during the week.",
        shortDescription: "How to manage your Tasks on your calendar",
        duration: "5min",
        entryPathname: "/planner",
        icon: DateRangeOutlinedIcon,
        steps: [
          {
            id: "PLANNER_MANAGE_TASKS",
            type: "orb",
            popoverConfig: {
              description: "Watch a quick video on managing your Tasks.",
              img: "/img/quests/quests-tasks.gif",
            },
          },
          { id: "VIDEO_MANAGE_TASKS", type: "video", videoLink: "8ApgSiGKhoc" },
        ],
      },
      {
        id: "SET_TASK_DEFAULTS",
        groupId: "TASKS",
        title: "Set your Task defaults",
        description: "Define your default settings for how Tasks are scheduled via Reclaim.",
        shortDescription: "Set defaults for how Tasks are scheduled",
        duration: "2min",
        entryPathname: "/settings/general#tasks",
        icon: CogIcon,
        indeterminant: true,
        steps: [
          {
            id: "TASK_DEFAULTS_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "Edit your default Task duration, preferred hours, visibility to others, & auto-reopen/close settings.",
            },
          },
        ],
      },
    ],
  },
  /********************/
  /*     CUSTOMIZE    */
  /********************/
  CUSTOMIZE_CALENDAR: {
    id: "CUSTOMIZE_CALENDAR",
    title: "Customize your calendar",
    quests: [
      {
        id: "AUTO_SCHEDULE_BREAK_AND_TRAVEL_TIME",
        groupId: "CUSTOMIZE_CALENDAR",
        title: "Auto-schedule breaks & travel",
        description: "Make time for breaks around meetings & work sessions.",
        shortDescription: "Make time for breaks between meetings",
        duration: "2min",
        entryPathname: "/settings/general#buffer",
        indeterminant: true,
        icon: CoffeeIcon,
        steps: [
          {
            id: "TRAVEL_TIME_INFO",
            type: "action",
            popoverConfig: {
              description: "Make sure you have time to travel between your events by automatically adding buffer time.",
              img: "/img/quests/travel-time.png",
            },
          },
          {
            id: "CLICK_TRAVEL_TIME",
            type: "action",
            popoverConfig: {
              description: "Toggle travel time.",
            },
          },
          {
            id: "CONFERENCE_BUFFER_INFO",
            type: "action",
            popoverConfig: {
              description: "Automatically schedule breaks after video calls so you have time to decompress.",
              img: "/img/quests/buffer-time.png",
            },
          },
          {
            id: "CLICK_BUFFER",
            type: "action",
            popoverConfig: {
              description: "Toggle buffer time.",
            },
          },
        ],
      },
      {
        id: "SETUP_NO_MEETING_DAYS",
        groupId: "CUSTOMIZE_CALENDAR",
        title: "Set up No-Meetings Days",
        description:
          "Create No-Meetings Days for you & your team to prevent meetings, while still scheduling Habits, Tasks & breaks.",
        shortDescription: "Use hashtags to set no-meetings days",
        duration: "2min",
        icon: HashtagIcon,
        steps: [{ id: "VIDEO_NO_MEETING_DAYS", type: "video", videoLink: "MORi7KIYJkw" }],
      },
      {
        id: "CUSTOMIZE_EVENT_COLOR_CODING",
        groupId: "CUSTOMIZE_CALENDAR",
        title: "Customize event color-coding",
        description: "Automatically color-code your events by category to keep your schedule visually organized.",
        shortDescription: "Color-code your events by category",
        duration: "2min",
        entryPathname: "/settings/colors",
        icon: ColorLensOutlinedIcon,
        indeterminant: true,
        steps: [
          {
            id: "COLOR_SETTINGS_OVERVIEW",
            type: "orb",
            popoverConfig: {
              description: "Reclaim-created events will use the default category colors you set up here.",
              img: "/img/quests/colors.png",
            },
          },
          {
            id: "ENABLE_ALL_EVENTS",
            type: "action",
            popoverConfig: {
              description: "We recommend allowing Reclaim to color all of your events based on these categories.",
            },
          },
        ],
      },
      {
        id: "TRACK_AND_ANALYZE_PRODUCTIVITY",
        groupId: "CUSTOMIZE_CALENDAR",
        title: "Track & analyze your productivity",
        description: "Check out your calendar stats to keep tabs on your productivity & work-life balance.",
        shortDescription: "Keep tabs on your productivity & work-life balance",
        duration: "2min",
        entryPathname: "/stats",
        icon: BarChartRoundedIcon,
        steps: [
          {
            id: "STATS_OVERVIEW",
            type: "orb",
            popoverConfig: {
              description: "Use these stats to balance & budget where your time goes each week.",
            },
          },
        ],
      },
    ],
  },
  /********************/
  /*       TEAM       */
  /********************/
  INVITE_TEAM: {
    id: "INVITE_TEAM",
    title: "Invite your team",
    quests: [
      {
        id: "ADD_MEMBERS_TO_TEAM",
        groupId: "INVITE_TEAM",
        title: "Add members to your team",
        description: "Start building out your team to get the most out of Reclaim.",
        shortDescription: "Build your team & get the most out of Reclaim",
        duration: "3min",
        entryPathname: "/billing#team",
        icon: UserAddIcon,
        indeterminant: true,
        steps: [
          {
            id: "CLICK_ADD_MEMBER",
            type: "action",
            popoverConfig: {
              description: "Tap to invite a team member.",
            },
          },
          {
            id: "INVITE_INFO",
            type: "orb",
            popoverConfig: {
              description:
                "Enter a team member's email to send a free invite - you can add additional emails right below.",
            },
          },
          {
            id: "CLICK_INVITE",
            type: "action",
            popoverConfig: {
              description: "Tap to send.",
            },
          },
        ],
      },
      {
        id: "SELECT_PLAN",
        groupId: "INVITE_TEAM",
        title: "Select a plan",
        description: "Find the right Reclaim plan for your team's needs.",
        shortDescription: "Find the right plan for your needs",
        duration: "2min",
        entryPathname: "/billing",
        icon: GridIcon,
        indeterminant: true,
        steps: [
          {
            id: "CLICK_PURCHASE",
            type: "action",
            popoverConfig: {
              description: "Tap the purchase button to compare plans & select one for your account.",
              img: "/img/quests/purchase.png",
            },
          },
          {
            id: "PURCHASE_INFO",
            type: "orb",
            popoverConfig: {
              description: "Learn more about our Pro & Team plans & choose what's right for you.",
            },
          },
          {
            id: "CLICK_FINISH_ON_STRIPE",
            type: "action",
            popoverConfig: {
              description: "Set up payment.",
            },
          },
        ],
      },
    ],
  },
};
