import { SortStateComparitor } from "../utils/sort";
import { RecurringAssignmentInstance } from "./OneOnOnes";
import { Task, TaskInstance } from "./Tasks";

export const taskFinishedComparitor: SortStateComparitor<Task> = (a, b) => {
  if (a.finished?.getTime() === b.finished?.getTime()) return 0;
  if (!a.finished) return -1;
  if (!b.finished) return 1;
  return b.finished.getTime() - a.finished.getTime();
};

export const taskIndexComparitor: SortStateComparitor<Task> = (a, b) =>
  (!!a.index || a.index === 0 ? a.index : Infinity) - (!!b.index || b.index === 0 ? b.index : Infinity);

export const instanceStartTimeComparitor: SortStateComparitor<TaskInstance | RecurringAssignmentInstance> = (a, b) => {
  if (a.start?.getTime() === b.start?.getTime()) return 0;
  if (!a.start) return 1;
  if (!b.start) return -1;
  return a.start.getTime() - b.start.getTime();
};
